<template>
  <div class="about">
    <div class="about-content">
      <div class="item">
        <div class="label">address</div>
        <input type="text" v-model="address" disabled>
      </div>
      <div class="item">
        <div class="label">输入chainId</div>
        <input type="text" v-model="chainId">
      </div>
      <div class="item">
        <div class="label">输入rpc</div>
        <input type="text" v-model="rpc">
      </div>
      <div class="item">
        <div class="label">输入合约地址</div>
        <input type="text" v-model="contractAddress">
      </div>
      <div class="btns">
        <div class="btn" @click="connectTestnet">连接网络</div>
        <div class="btn" @click="mint">mint</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, watch, computed, toRaw } from "vue";
import { useStore } from 'vuex'
import { addEthereumChain, walletSwitchChain } from '../libs/walletSwitchChain'
import { contractData } from '../config/contract'
import { contract } from "../libs/connectWallet";
// import { ethers } from "ethers";
export default {
  setup() {
    const store = useStore()
    const chainId = ref('')
    const contractAddress = ref('')
    const rpc = ref('')
    let C = null
    let isConnect = false
    const web3 = computed(() => {
      return store.state.web3
    })
    const address = computed(() => {
      return store.state.address
    })
    const connectTestnet = () => {
      if (!address.value) {
        store.commit('getWeb3')
        isConnect = true
      } else {
        isConnect = false
        let nativeCurrency = {}
        if (chainId.value == 5) {
          nativeCurrency = {
            name: 'Eth',
            symbol: 'Eth',
            decimals: 18
          }
        } else if (chainId.value == 80001) {
          nativeCurrency = {
            name: 'Matic',
            symbol: 'Matic',
            decimals: 18
          }
        } else {
          nativeCurrency = {
            name: 'Eth',
            symbol: 'Eth',
            decimals: 18
          }
        }
        console.log(chainId.value)
        let param = {
          chainId: '0x' + (+chainId.value).toString(16),
          chainName: 'Jetable Mumbai',
          nativeCurrency,
          rpcUrls: [rpc.value]
        }
        addEthereumChain(param).then(async res => {
          console.log(res)
          await walletSwitchChain(chainId.value)
        }).catch(err => {
          console.error(err)
        })
      }
    }
    const mint = async () => {
      let tx
      try {
        tx = await C.mint()
      } catch (error) {
        console.error(error)
        return
      }
      alert('mint成功')
      console.info('tx:', tx)
    }
    watch(web3, async (value) => {
      console.log(value)
      if (value) {
        let user = toRaw(value).getSigner()
        C = await contract(contractAddress.value, contractData.abi, user)
        console.log(C)
        if (isConnect) {
          connectTestnet()
        }
      }
    }, {immediate: true})
    return {
      chainId,
      rpc,
      address,
      contractAddress,
      connectTestnet,
      mint
    }
  },
}
</script>
<style lang="scss" scoped>
.about-content {
  width: 1280px;
  height: 100vh;
  margin: auto;
  padding: 100px;
  box-sizing: border-box;
  font-size: 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    .label {
      font-size: 16px;
      width: 100px;
      text-align: right;
      color: #fff;
    }
    input {
      height: 30px;
      border: none;
      width: 500px;
      border-radius: 6px;
      margin-left: 20px;
      outline: none;
      padding: 0 10px;
      box-sizing: border-box;
      &:disabled {
        cursor: default;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(0, 0, 0, 1);
        border-color: rgba(118, 118, 118, 0.3);
      }
    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 10px;
    color: #000;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
  }
}
</style>
